<template>
  <aside>
    <header>
      <h2>Postpay Reward Receipt</h2>
      <button class="close" @click="close"><span class="sr-only">Close modal window</span></button>
    </header>

    <main ref="receipt-body">
      <template v-if="payment_intent !== null">
        <h1>Receipt for your latest reward redemptions</h1>
        <p>We've charged your credit card ending in <strong>x{{charge.payment_method_details.card.last4}}</strong> for your latest reward redemptions. Please save this receipt for your records.</p>

        <ul class="transaction-details">
          <li>
            <label>Transaction ID</label>
            <span>
              {{payment_intent.id}}
            </span>
          </li>

          <li>
            <label>Transaction date</label>
            <span>
              {{$formatDate(payment.payment_time, $DateTime.DATETIME_FULL)}}
            </span>
          </li>

          <li>
            <label>Rewards cost</label>
            <span>
              {{$formatCurrency(total_points/100)}}
            </span>
          </li>

          <li>
            <label>Platform fee</label>
            <span>
              {{$formatCurrency((total_points*0.1)/100)}}
            </span>
          </li>

          <li>
            <label>Total charged</label>
            <span>
              {{$formatCurrency(payment_intent.amount/100)}}
            </span>
          </li>

          <li>
            <label>Payment method</label>
            <span>
              <div class="payment-method">
                <strong>{{charge.payment_method_details.card.brand.toUpperCase()}}</strong>
                <div>x{{charge.payment_method_details.card.last4}} <small>({{charge.payment_method_details.card.exp_month}}/{{charge.payment_method_details.card.exp_year}})</small></div>
              </div>
            </span>
          </li>
        </ul>

        <h4>Redemptions</h4>
        <ul class="redemptions">
          <li v-for="redemption in redemptions" :key="`redemption_${redemption.id}`">
            <user-card height="22" :user="redemption.user" /> redeemed <strong>{{$formatPoints(redemption.points)}}</strong> through the <strong>{{catalogName(redemption.type)}}</strong> catalog on <strong>{{$formatDate(redemption.created_at)}}</strong>.
          </li>
        </ul>
      </template>
      <loading-indicator small v-else />
    </main>

    <footer>
      <button :class="['btn', 'btn-light', 'btn-sm', {'loading': loading}]" :disabled="loading" @click="savePDF">Save PDF</button>
    </footer>
  </aside>
</template>

<script>
import labelmake from 'labelmake'
import {PDFDocument} from 'pdf-lib'

export default {
  props: ['data'],
  computed: {
    charge() {
      return this.payment_intent.charges.data[0];
    },
    total_points() {
      return _.sumBy(this.redemptions, 'points')
    }
  },
  data() {
    return {
      payment_intent: null,
      payment: null,
      redemptions: [],
      loading: false
    }
  },
  mounted() {
    this.payment = _.cloneDeep(this.data.payment);
    this.redemptions = this.payment.redemptions;

    this.populatePaymentIntent();
  },
  methods: {
    close() {
      this.$emit('done');
    },
    catalogName(type) {
      switch(type) {
        case 'AMAZON':
          return 'Amazon';
        case 'PAYPAL':
          return 'PayPal';
        case 'GIFT_CARD':
          return 'gift card';
        case 'DONATION':
          return 'charity';
          
      }
    },
    async populatePaymentIntent() {
      const resp = await this.$api.Billing.get_reward_payment(this.payment.payment_id)

      this.payment_intent = resp;
    },
    async savePDF() {
      this.loading = true;

      const basePdf_FirstPage = await fetch(require("@/assets/RewardPDFFirstPage.pdf")).then((res) => res.arrayBuffer());
      const basePdf_AddlPage = await fetch(require("@/assets/RewardPDFAddlPage.pdf")).then((res) => res.arrayBuffer());

      const lineItemValues = {};
      const getLineItems = (page=1) => {
        let [start, end] = [0, 0];

        if(page === 1) {
          end = 18;
        }
        else {
          start = (page === 2 ? 18 : (36 * page)) + 1;
          end = start + 36;
        }

        console.log(start, end)

        const redemptions = this.redemptions.slice(start, end)

        if(redemptions.length === 0) return null;

        return redemptions.reduce((agg, val, index) => {
          const yPos = (page === 1 ? 157.39 : 44.08) + (5.71 * index);
          Object.assign(agg, {
            [`rli_${index}_user`]: {
                "type": "text",
                "position": {
                  "x": 9,
                  "y": yPos
                },
                "width": 60.66,
                "height": 5.41,
                "alignment": "left",
                "fontSize": 10,
                "characterSpacing": 0,
                "lineHeight": 1
              },
              [`rli_${index}_rewardtype`]: {
                "type": "text",
                "position": {
                  "x": 74.35,
                  "y": yPos
                },
                "width": 46.91,
                "height": 5.41,
                "alignment": "left",
                "fontSize": 10,
                "characterSpacing": 0,
                "lineHeight": 1
              },
              [`rli_${index}_date`]: {
                "type": "text",
                "position": {
                  "x": 126.73,
                  "y": yPos
                },
                "width": 43.47,
                "height": 5.41,
                "alignment": "left",
                "fontSize": 10,
                "characterSpacing": 0,
                "lineHeight": 1
              },
              [`rli_${index}_points`]: {
                "type": "text",
                "position": {
                  "x": 174.89,
                  "y": yPos
                },
                "width": 31.29,
                "height": 5.41,
                "alignment": "left",
                "fontSize": 10,
                "characterSpacing": 0,
                "lineHeight": 1
              }
          })

          Object.assign(lineItemValues, {
            [`rli_${index}_user`]: val.user.name,
            [`rli_${index}_rewardtype`]: this.catalogName(val.type),
            [`rli_${index}_date`]: this.$formatDate(val.created_at),
            [`rli_${index}_points`]: this.$formatPoints(val.points)
          })
          return agg;
        }, {})
      };

      const schemas_FirstPage = [
        {
          "tx_id": {
            "type": "text",
            "position": {
              "x": 50.54,
              "y": 70.12
            },
            "width": 147.18,
            "height": 5.41,
            "alignment": "left",
            "fontSize": 12,
            "characterSpacing": 0,
            "lineHeight": 1
          },
          "tx_date": {
            "type": "text",
            "position": {
              "x": 50.54,
              "y": 79.89
            },
            "width": 147.18,
            "height": 5.41,
            "alignment": "left",
            "fontSize": 12,
            "characterSpacing": 0,
            "lineHeight": 1
          },
          "rewards_cost": {
            "type": "text",
            "position": {
              "x": 50.54,
              "y": 89.69
            },
            "width": 147.18,
            "height": 5.41,
            "alignment": "left",
            "fontSize": 12,
            "characterSpacing": 0,
            "lineHeight": 1
          },
          "platform_fee": {
            "type": "text",
            "position": {
              "x": 50.54,
              "y": 100.51
            },
            "width": 147.18,
            "height": 5.41,
            "alignment": "left",
            "fontSize": 12,
            "characterSpacing": 0,
            "lineHeight": 1
          },
          "total_charged": {
            "type": "text",
            "position": {
              "x": 50.54,
              "y": 110.54
            },
            "width": 147.18,
            "height": 5.41,
            "alignment": "left",
            "fontSize": 12,
            "characterSpacing": 0,
            "lineHeight": 1
          },
          "payment_method": {
            "type": "text",
            "position": {
              "x": 50.54,
              "y": 120.8
            },
            "width": 147.18,
            "height": 5.41,
            "alignment": "left",
            "fontSize": 12,
            "characterSpacing": 0,
            "lineHeight": 1
          },
          ...getLineItems(1)
        }
      ];

      const templateFirstPage = {
        basePdf: basePdf_FirstPage,
        schemas: schemas_FirstPage
      };

      const pdfPages = [];

      const firstPagePDF = await labelmake({
        template: templateFirstPage,
        inputs: [{
          ...lineItemValues,
          tx_id: this.payment_intent.id,
          tx_date: this.$formatDate(this.payment.payment_time, this.$DateTime.DATETIME_FULL),
          rewards_cost: this.$formatCurrency(this.total_points/100),
          platform_fee: this.$formatCurrency((this.total_points*0.1)/100),
          total_charged: this.$formatCurrency(this.payment_intent.amount/100),
          payment_method: this.charge.payment_method_details.card.brand.toUpperCase() + ' (x'+this.charge.payment_method_details.card.last4+')'
        }]
      })

      pdfPages.push(firstPagePDF)

      //try addl pages
      let hasAddlPages = 2;
      while(hasAddlPages !== false) {
        const pageLineItems = getLineItems(hasAddlPages)

        if(pageLineItems === null) {
          hasAddlPages = false;
          break;
        }

        const addlPagePdf = await labelmake({
          template: {
            basePdf: basePdf_AddlPage,
            schemas: [pageLineItems]
          },
          inputs: [lineItemValues]
        })

        pdfPages.push(addlPagePdf)

        hasAddlPages ++;
      }

      const pdfDoc = await PDFDocument.create();
      for(const pdfPage of pdfPages) {
        const plPage = await PDFDocument.load(pdfPage.buffer)
        const [page] = await pdfDoc.copyPages(plPage, [0])
        pdfDoc.addPage(page)
      }

      const pdfBytes = await pdfDoc.save()

      const blob = new Blob([pdfBytes], { type: "application/pdf" });
      window.open( URL.createObjectURL(blob) )

      this.loading = false;
    }
  }
}
</script>

<style scoped lang="scss">
.transaction-details {
  padding: 0;
  margin: 25px 0 0;
  display: block;

  > li {
    display: flex;
    padding: 5px 0;

    > label {
      display: block;
      flex: 0 0 30%;
      color: $muted-text;
      margin: 0;
    }

    > span {
      display: block;
      flex: 0 0 auto;
      margin: 0 0 0 auto;
    }
  }
}

h4 {
  font-weight: 700;
  font-size: 16px;
  margin: 25px 0 0;
}

.redemptions {
  margin: 10px 0 0;

  display: block;
  padding: 0;

  > li {
    display: block;
    padding: 2px 0 2px 10px;
    position: relative;
    margin: 0;

    &:before {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      background: $light;
      border-radius: 100em;
      width: 4px;
    }

    >.user--card {
      display: inline-flex;
      vertical-align: -6px;
    }

    +li {
      margin-top: 10px;
    }
  }
}

.payment-method {
  display: flex;
  align-items: center;

  > strong {
    margin: 0 5px 0 0;
  }

  > div {
    padding: 0 0 0;
    line-height: 12px;
    font-size: 12px;
    > small {
      color: $muted-text;
    }
  }
}

h1 {
  font-weight: 700;
  font-size: 22px;

  + p {
    svg {
      display: inline-block;
      height: 20px;
      border-radius: 3px;
    }
  }
}
</style>